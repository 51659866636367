<template>
  <div class="bg-white py-6 px-4 sm:p-6 shadow rounded-t">
    <form
      id="password-change"
      @submit.prevent="handleSubmit"
      class="space-y-8 mb-8"
    >
      <div class="grid gap-6 lg:grid-cols-2 md:grid-cols-2">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Password</h3>
          <p class="mt-1 text-sm text-gray-500">Update your password</p>
          <div>
            <div class="grid gap-6 mt-6">
              <input hidden type="text" autocomplete="username" />

              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Current password
                </label>
                <div class="mt-1">
                  <input
                    v-model="password.password"
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
              <div class="space-y-1">
                <label
                  for="new-password"
                  class="block text-sm font-medium text-gray-700"
                >
                  New password
                </label>
                <div class="mt-1">
                  <input
                    id="new-password"
                    v-model="password.new_password"
                    name="new-password"
                    type="password"
                    autocomplete="new-password"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Confirm password
                </label>
                <div class="mt-1">
                  <input
                    v-model="password.confirm_password"
                    id="confirm-password"
                    name="confirm-password"
                    type="password"
                    autocomplete="confirm-password"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-blue-500
                      focus:border-blue-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            bg-gray-50
            py-6
            px-4
            sm:p-6
            shadow
            rounded
            max-w-xs
            grid
            gap-4
            mx-auto
          "
        >
          <ShieldCheckIcon class="text-green-500 h-14" />
          <h1 class="text-lg text-gray-900 font-semibold">
            Rules for passwords
          </h1>
          <h2 class="text-gray-700">
            To create a new password, you have to meet all of the following
            requirements:
          </h2>
          <ol>
            <li class="inline-flex items-center text-gray-700">
              <CheckIcon class="h-5 text-green-500 mr-2" />Minimum 8 character
            </li>
            <li class="inline-flex items-center">
              <CheckIcon class="h-5 text-green-500 mr-2" />At least one special
              character
            </li>
            <li class="inline-flex items-center">
              <CheckIcon class="h-5 text-green-500 mr-2" />At least one number
            </li>
            <li class="inline-flex items-center">
              <CheckIcon class="h-5 text-green-500 mr-2" />Can’t be the same as
              a previous
            </li>
          </ol>
        </div>
      </div>
    </form>
  </div>
  <div
    class="
      space-x-4
      flex
      justify-end
      bg-gray-50
      py-6
      px-4
      sm:p-6
      shadow
      rounded-b
    "
  >
    <button
      class="
        inline-flex
        items-center
        px-4
        py-2
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-blue-700
        bg-blue-100
        hover:bg-blue-200
        focus:outline-none
        focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500
      "
    >
      Cancel
    </button>
    <button
      form="password-change"
      type="submit"
      class="
        inline-flex
        items-center
        px-4
        py-2
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-white
        bg-light-blue-600
        hover:bg-light-blue-700
        focus:outline-none
        focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500
      "
    >
      Save
    </button>
  </div>
</template>


<script>
import { ShieldCheckIcon, CheckIcon } from "@heroicons/vue/solid";
import { changePassword } from "../../../services/auth";
import { ref } from "vue";
import { useToast } from "vue-toastification";
export default {
  setup() {
    const toast = useToast();
    const password = ref({
      password: "",
      new_password: "",
      confirm_password: "",
    });
    const handleSubmit = async () => {
      await changePassword(password.value);
      toast.success("Password changed successfully");
      password.value({
        password: "",
        new_password: "",
        confirm_password: "",
      });
    };

    return {
      handleSubmit,
      password,
    };
  },
  components: {
    ShieldCheckIcon,
    CheckIcon,
  },
};
</script>

